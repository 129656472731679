<template>
    <div class="stage-inner-container-padded">
        <div v-if="!fetchingDevice && !hasError">
            <b-container>
                <b-row align-h="center">
                    <b-col cols="12">
                        <h2>{{survey_type}}</h2>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col>
                        <h4>{{surveyData.raw.event_tstamp}} - {{surveyData.raw.technician}}</h4>
                    </b-col>
                </b-row>

                

                <b-row align-h="center" v-if="showSection.event_notes">
                    <b-col>
                        Event Notes
                    </b-col>
                </b-row>

                <b-row align-h="center" v-if="showSection.event_notes">
                    <b-col>
                        <pre>{{surveyData.raw.event_notes_pretty}}</pre>
                    </b-col>
                </b-row>

                <!-- Start Survey Photo & Notes Section -->
                <b-row v-if="showSection.survey_photo || showSection.survey_notes" align-h="center">
                    <b-col align-self="center">
                        <!-- Survey Photo And Notes Section Title -->
                        <h3 v-if="showSection.survey_photo && !showSection.survey_notes" >Survey Photo</h3>
                        <h3 v-else-if="showSection.survey_photo && showSection.survey_notes" >Survey Photo & Notes</h3>
                        <h3 v-else-if="!showSection.survey_photo && showSection.survey_notes" >Survey Notes</h3>
                    </b-col>
                </b-row>
                <b-row v-if="showSection.survey_photo || showSection.survey_notes" no-gutters>
                    <b-col v-if="showSection.survey_photo" cols="12" sm="12" md="6" lg="3" xl="3">
                        <!-- Survey Photo -->
                        <b-img :src="surveyData.survey_photo.thumb.link" fluid alt="Survey Photo" @click="enlargePhoto(surveyData.survey_photo, 'Survey Photo')"></b-img>
                    </b-col>
                    <b-col v-if="showSection.survey_notes" cols="12" sm="12" md="6" lg="9" xl="9">
                        <!-- Survey Notes -->
                        <b-row>
                            <b-col>
                                <b>Survey Notes:</b>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                {{surveyData.raw.survey_notes}}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <!-- End Survey Photo & Notes Section -->
                <!-- Start Condition Photo & Notes Section -->
                <b-row v-if="showSection.condition_photo || showSection.condition_notes || showSection.condition" align-h="center">
                    <b-col align-self="center">
                        <!-- Condition Photo And Notes Section Title -->
                        <h3>Condition</h3>
                    </b-col>
                </b-row>
                <b-row v-if="showSection.condition_photo || showSection.condition_notes || showSection.condition" no-gutters>
                    <b-col v-if="showSection.condition_photo" cols="12" sm="12" md="6" lg="3" xl="3">
                        <!-- Condition Photo -->
                        <b-img :src="surveyData.condition_photo.thumb.link" fluid alt="Survey Photo" @click="enlargePhoto(surveyData.condition_photo, 'Condition Photo')"></b-img>
                    </b-col>
                    <b-col v-if="showSection.condition_notes || showSection.condition" cols="12" sm="12" md="6" lg="9" xl="9">
                        <!-- Condition -->
                        <b-row v-if="showSection.condition">
                            <b-col>
                                <b>Condition:</b> {{surveyData.raw.condition.toString()}}
                            </b-col>
                        </b-row>
                         <b-row v-if="showSection.condition_notes">
                            <b-col>
                                <b>Condition Notes:</b>
                            </b-col>
                        </b-row>
                        <b-row v-if="showSection.condition_notes">
                            <b-col>
                                {{surveyData.raw.condition_notes}}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <!-- End Condition Photo & Notes Section -->
                <!-- Start Hazard Photo Section -->
                <b-row v-if="showSection.hazard_photo" align-h="center">
                    <b-col align-self="center">
                        <!-- Hazard Photo And Notes Section Title -->
                        <h3>Hazard Photo</h3>
                    </b-col>
                </b-row>
                <b-row v-if="showSection.hazard_photo" no-gutters>
                    <b-col v-if="showSection.hazard_photo" cols="12" sm="12" md="6" lg="3" xl="3">
                        <!-- Hazard Photo -->
                        <b-img :src="surveyData.hazard_photo.thumb.link" fluid alt="Hazard Photo Photo" @click="enlargePhoto(surveyData.hazard_photo, 'Hazard Photo')"></b-img>
                    </b-col>
                </b-row>
                <!-- End Hazard Photo Section -->
                <!-- Start Connection Changelog Section -->
                <div v-if="surveyData.raw.connection_log_id != null">
                    <b-row align-h="center">
                        <b-col align-self="center" v-if="surveyData.raw.connection_updated">
                            <h3>Connection ChangeLog</h3>
                        </b-col>
                        <b-col align-self="center" v-else>
                            <h3>Connection At Time Of Event (Event Made No Changes)</h3>
                        </b-col>
                    </b-row>
                    <b-row v-for="(cl, index) in connectionChangelog" :key="index + '_ccl'">
                        <b-col v-bind:class="[cl.changed && surveyData.raw.connection_updated ? 'changed-left' : 'not-changed-left']">
                            <b>{{cl.field}}</b>
                        </b-col>
                        <b-col v-if="surveyData.raw.connection_updated" v-bind:class="[cl.changed && surveyData.raw.connection_updated ? 'changed' : 'not-changed']">
                            {{cl.original}}
                        </b-col>
                        <b-col v-else-if="cl.field == 'stage' && cl.updated == 'updated'" class='not-changed'>
                            Values At Time Of Event
                        </b-col>
                        <b-col v-else class='not-changed'>
                            {{cl.updated}}
                        </b-col>
                        <b-col v-if="surveyData.raw.connection_updated" v-bind:class="[cl.changed && surveyData.raw.connection_updated ? 'changed' : 'not-changed']">
                            {{cl.updated}}
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-row align-h="center" >
                        <b-col align-self="center">
                            <h3>Connection ChangeLog Not Available</h3>
                        </b-col>
                    </b-row>
                </div>
                
                <!-- End Connection Changelog Section -->

                <!-- Start Device Changelog Section -->
                <div v-if="surveyData.raw.device_log_id != null">
                    <b-row align-h="center">
                        <b-col align-self="center" v-if="surveyData.raw.device_updated">
                            <h3>Device ChangeLog</h3>
                        </b-col>
                        <b-col align-self="center" v-else>
                            <h3>Device At Time Of Event (Event Made No Changes)</h3>
                        </b-col>
                    </b-row>
                    <b-row v-for="(dcl, index) in deviceChangelog" :key="index + '_dcl'">
                        <b-col v-bind:class="[dcl.changed && surveyData.raw.device_updated ? 'changed-left' : 'not-changed-left']">
                            <b>{{dcl.field}}</b>
                        </b-col>
                        <b-col v-if="surveyData.raw.device_updated" v-bind:class="[dcl.changed && surveyData.raw.device_updated ? 'changed' : 'not-changed']">
                            {{dcl.original}}
                        </b-col>
                        <b-col v-else-if="dcl.field == 'stage' && dcl.updated == 'updated'" class='not-changed'>
                            Values At Time Of Event
                        </b-col>
                        <b-col v-else class='not-changed'>
                            {{dcl.updated}}
                        </b-col>
                        <b-col v-if="surveyData.raw.device_updated" v-bind:class="[dcl.changed && surveyData.raw.device_updated ? 'changed' : 'not-changed']">
                            {{dcl.updated}}
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-row align-h="center" >
                        <b-col align-self="center">
                            <h3>Device ChangeLog Not Available</h3>
                        </b-col>
                    </b-row>
                </div>
                <!-- End Device Changelog Section -->

                <b-row align-h="center">
                    <b-col align-self="center">
                        <b-button block v-show="!editModal.processing" class="mt-3" variant="info" @click="goBackToSearch()">Done</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else-if="fetchingDevice">
            <b-container>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center">
                            <b-spinner style="width: 5rem; height: 5rem;" label="Loading..." type="grow"></b-spinner>
                        </div>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center" >
                            <h3>{{loading_message}}</h3>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <b-container>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center">
                            <b-img src="/warning.svg" fluid alt="Fluid image"></b-img>
                        </div>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center" >
                            <h3>{{hasErrorValue}}</h3>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <b-modal ref="gallery-modal" id="modal-xl" size="xl" :ok-only="true" :title="'View ' + photoModal.title + ' Details'">
            <b-row>
                <b-col md="12" lg="6">
                    <b-img-lazy v-bind:src="photoModal.source" fluid ></b-img-lazy>
                </b-col>
                <b-col md="12" lg="6">
                    <div v-if="photoModal.gps.latitude != ''">
                        <span>
                            <strong><em>Photo Location Information:</em></strong>
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Accuracy:</em>
                            {{photoModal.gps.dop}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Latitude:</em>
                            {{photoModal.gps.latitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Longitude:</em>
                            {{photoModal.gps.longitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Altitude:</em>
                            {{photoModal.gps.altitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Timestamp:</em>
                            {{photoModal.gps.timestamp}}
                        </span>
                        <br />
                        <b-button class="tab-in" variant="info" size="sm" :href="photoModal.gmapViewLink" target="_blank">View GPS Location On Google Maps</b-button>
                        <hr>
                    </div>
                <span>
                    <strong><em>Available Actions:</em></strong>
                </span>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    :href="photoModal.sizes.original.link" 
                    download 
                    target="_blank"
                >
                    View Original Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.sizes.large != null"
                    :href="photoModal.sizes.large.link" 
                    download 
                    target="_blank"
                >
                    View Large Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.sizes.small != null"
                    :href="photoModal.sizes.small.link" 
                    download 
                    target="_blank"
                >
                    View Small Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.sizes.thumbnail != null"
                    :href="photoModal.sizes.thumbnail.link" 
                    download 
                    target="_blank"
                >
                    View Thumbnail Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.hasExif"
                    @click="toggleShowExifData()"
                >
                    {{(photoModal.showExif) ? "Hide Detailed Photo Info (EXIF)" : "View Detailed Photo Info (EXIF)"}}
                </b-button>
                </b-col>
            </b-row>
            <b-row v-if="photoModal.showExif">
                <b-col cols="12">
                    <hr>
                    <b-card sub-title="Server Data:">
                        <span class="tab-in">
                            <em>Original File Name:</em> {{photoModal.uploadFileName}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Uploaded On:</em> {{photoModal.uploadDate}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Uploaded By User ID:</em> {{photoModal.uploadedBy}}
                        </span>
                    </b-card>
                    <hr>
                    <b-card sub-title="Exif Data">
                        <div class="tab-in" v-for="(itm, index) in photoModal.exifKeys" :key="index">
                            <span>
                                <em>{{itm}}: </em> {{photoModal.exif[itm]}}
                            </span>
                            <br />
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <template v-slot:modal-footer="{ ok }">
                <small class="text-muted" v-if="photoModal.gps.timestamp != ''">Photo Taken On: {{photoModal.gps.timestamp}}</small>
                <small class="text-muted" v-else-if="photoModal.uploadDate != null">Photo Uploaded To System On: {{photoModal.uploadDate}}</small>
                <b-button @click="ok()" variant="primary">Done</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const jpgInstance = axios.create({ timeout: 10000, headers: {'Content-Type': 'image/jpeg'}, withCredentials: true, crossdomain: true });
const pngInstance = axios.create({ timeout: 10000, headers: {'Content-Type': 'image/png'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
export default {
    name: 'viewsurvey',
    props: ['eventID'],
    components: {
    },
    data(){
        return{
            hasError: false,
            hasErrorValue: '',
            fetchingDevice: true,
            loading_message: 'Instantiating Instantiation Engine',
            required_requests: 1,
            completed_requests: 0,

            survey_type: 'Survey / Field Update',
            hasPhoto: {
                survey_photo: false,
                hazard_photo: false,
                condition_photo: false,
            },
            showSection: {
                survey_notes: false,
                survey_photo: false,
                hazard_photo: false,
                condition: false,
                condition_notes: false,
                condition_photo: false
            },
            surveyData: {
                raw: {},
                survey_photo: null,
                condition_photo: null,
                hazard_photo: null,
            },
            api_path: '',
            photoModal:{
                title: null,
                showExif: false,
                uploadDate: null,
                uploadFileName: null,
                uploadedBy: null,
                source: '',
                sourceIs: 'original',
                sizes: {
                    thumbnail: null,
                    small: null,
                    large: null,
                    original: {
                        MIMEType: null,
                        link: null,
                        size: null
                    }
                },
                gps:{
                    altitude: '',
                    dop: '',
                    latitude: '',
                    longitude: '',
                    timestamp: ''
                },
                hasExif: false,
                exifKeys: [],
                exif:{},
                gmapViewLink: null
            },
            connectionChangelog:[],
            deviceChangelog:[],

            
            showAddressSelect: false,
            parent_path: '',
            pkid: 'event_id',
            name_singular: 'Survey',
            name_pluaral: 'Surveys',
            visible_fields: ['' ],
            allPossibleFields: [],
            tableFields: [],
            values: [],
            hasNoValues: false,
            
            editModal: {
                type: 'Edit',
                fields: [],
                actee: {},
                parentChoices: [],
                deviceModels: [],
                gradeTypes: [],
                protectionTypes: [],
                bfpa_photo: null,
                tech_signature: null,
                fl_signature: null,
                processing: false,
                adrsInput: '',
                adrsFilled: false,
                showAdvancedAdrs: false,
                parsedAddress: {}
            },
            hasAddress: false,
            addressName: null,
            confirmationModal: {
                title: '',
                type: '',
                callbackPass: {},
                callback: ()=>{}
            }
        }
    },
    methods: {
        showChanges(){

        },
        fetchPageData () {
            //console.log(this)
            //Get User Token and Fetch The Values Required For This Page
            return new Promise( (resolve, reject) =>{
                instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '/' + this.eventID + '.json?changesets=true')
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if(error.response.status == 404){
                        // Unable To Find Event
                        this.hasError = true;
                        this.hasErrorValue = "Could Not Find Event With Specified ID In Database";
                    }
                    console.log(error);
                    reject(error);
                });
            })
            
        },
        fetchGenericPhoto(photoField, photoID){
            jpgInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/meta/' + photoID)
                .then((response) => {
                    console.log(`Fetched: ${photoField} ${photoID}`)
                    this.showSection[photoField] = true;
                    this.surveyData[photoField] = response.data.result;
                    this.completed_requests = this.completed_requests + 1;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchConnectionChangeLog(){
            instance.get(process.env.VUE_APP_API_BASE_URL + '/changesets/connection/' + this.surveyData.raw.connection_log_id + '.json')
                .then(async (response) => {
                    this.connectionChangelog = response.data.result;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchDeviceChangeLog(){
            instance.get(process.env.VUE_APP_API_BASE_URL + '/changesets/device/' + this.surveyData.raw.device_log_id + '.json')
                .then(async (response) => {
                    this.deviceChangelog = response.data.result;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        enlargePhoto(img, title){
            this.photoModal.title = title;
            this.photoModal.uploadDate = new Date(img.uploadInfo.uploadedOn).toString();
            this.photoModal.uploadFileName = img.uploadInfo.uploadFileName;
            this.photoModal.uploadedBy = img.uploadInfo.uploadedBy;
            if(_.has(img, 'exif')){
                this.photoModal.hasExif = true;
                this.photoModal.exif = img.exif;
                this.photoModal.exifKeys = _.keys(img.exif);
            }
            if(_.has(img, 'gps')){
                this.photoModal.gps = {
                    altitude: (_.has(img.gps, 'alt') ? img.gps.alt : ''),
                    dop: (_.has(img.gps, 'dop') ? img.gps.dop : ''),
                    latitude: (_.has(img.gps, 'lat') ? img.gps.lat : ''),
                    longitude: (_.has(img.gps, 'lng') ? img.gps.lng : ''),
                    timestamp: (_.has(img.gps, 'timestamp') ? new Date(img.gps.timestamp).toString() : ''),
                }
            }
            this.photoModal.sizes = {
                thumbnail: (_.has(img, 'thumb') ? img.thumb : null),
                small: (_.has(img, 'small') ? img.small : null),
                large: (_.has(img, 'large') ? img.large : null),
                original: (_.has(img, 'original') ? img.original : null),
            };

            if(this.photoModal.sizes.large != null){
                this.photoModal.source = this.photoModal.sizes.large.link;
                this.photoModal.sourceIs = 'large';
            }else{
                this.photoModal.source = this.photoModal.sizes.original.link;
                this.photoModal.sourceIs = 'original';
            }
            var map = "https://www.google.com/maps/search/?api=1&query=" +
                this.photoModal.gps.latitude + "," + this.photoModal.gps.longitude;
            this.photoModal.gmapViewLink = map;
            this.$refs["gallery-modal"].show();
        },
        toggleShowExifData(){
            this.photoModal.showExif = !this.photoModal.showExif;
        },


        checkSuitabilityForIssuance(){
            var modalActee = this.editModal.actee;
            var rawcheckme = this.values.filter( (v) => { return v.device_id == this.editModal.actee.device_id });
            if(rawcheckme.length < 1){
                console.log('Must Save The Record Before Issuing A Work Order');
                this.makeToast("Cannot Issue To Fulcrum", "Must Save The Record Before Issuing A Work Order");
            }else{
                var checkme = rawcheckme[0];
                if(checkme.device_latitude != null && checkme.device_longitude != null && checkme.assembly_id != null){
                    // Required Items For Issuance To Fulcrum Are Present
                    instance.post(process.env.VUE_APP_API_BASE_URL + '/webhooks/create/' + checkme.device_id, {})
                        .then(async (response) => {
                            console.log('Issue To Fulcrum Success');
                            this.success_toast("Successfully Issued", "Record Should Be Available");
                        })
                        .catch((error) => {
                            console.log('Issue To Fulcrum Error')
                            console.log(error);
                        });
                    
                }else{
                    // Check The Kind Of Failure We Have
                    if( checkme.device_latitude != modalActee.device_latitude || checkme.device_longitude != modalActee.device_longitude || checkme.assembly_id != modalActee.assembly_id){
                        console.log('Must Save The Record Before Issuing A Work Order');
                        this.makeToast("Cannot Issue To Fulcrum", "Missing Latitude, Longitude, and/or Assembly ID \nEdits Should Be Saved Before Attempting To Issue.");
                    }else{
                        console.log('Just Missing The Stuff');
                        this.makeToast("Cannot Issue To Fulcrum", "Missing Latitude, Longitude, and/or Assembly ID");
                    }
                }
            }
            
            
        },
        makeToast(title, content, append = false) {
            this.toastCount++
            this.$bvToast.toast(content, {
                title: `${title}`,
                autoHideDelay: 5000,
                appendToast: append,
                variant: 'danger',
                solid: true,
            })
        },
        success_toast(title, content, append = false) {
            this.toastCount++
            this.$bvToast.toast(content, {
                title: `${title}`,
                autoHideDelay: 5000,
                appendToast: append,
                variant: 'success',
                solid: true,
            })
        },
        removeArrayItem(main_key, index){
            console.log('Requested Removal Of:');
            console.log(this.editModal.actee[main_key][index])
            console.log(this.editModal.actee[main_key])
            this.editModal.actee[main_key].splice(index, 1);
            console.log(this.editModal.actee[main_key])
        },
        addArrayItem(main_key, input_key){
            //console.log(this.editModal[input_key]);
            var x = cloneDeep(this.editModal[input_key]);
            if(!Array.isArray(this.editModal.actee[main_key])){
                this.editModal.actee[main_key] = [];
            }
            this.editModal.actee[main_key].push(x);
            this.editModal[input_key] = '';

        }, 
        resetEditModal(){
            this.editModal.processing = false;
            this.editModal.fields = this.allPossibleFields;
            this.editModal.type = '';
            this.editModal.actee = {};
            //this.editModal.adrsInput = '';
        },
        openCreatorWindow(value){
            this.editModal.type = value;
            this.$bvModal.show('choice-editor');
        },
        editModalSave(){
            console.log(this.editModal);
            // Create Copy Of Actee
            var actCopy = cloneDeep(this.editModal.actee);
            // Remove The Calculated Fields
            delete actCopy.work_order_out; 
            this.commitChangeOrCreate( actCopy );
        },
        commitChangeOrCreate(input){
            
        },
        transformToProperCase(val){
            var tmp = val.replace(/_/g, " ");
            return this.capitalizeEachWord(tmp);
        },
        capitalizeEachWord(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        goBackToSearch(){
            // Push Back To Home
            this.$router.go(-1);
        }
        
    },
    watch: {
        completed_requests: function (newVal, oldVal){
            var messages = [
                'Instantiating Instantiation Engine', 
                'Aligning Covariance Matrices', 
                'Normalizing Data Metrics', 
                'Resolving Quantum Irregularites', 
                'Pushing Pixels Around', 
                'Complete'
            ];
            this.loading_message = messages[newVal];
            console.log("Completed: " + newVal + "/" + this.required_requests);
            if(newVal == this.required_requests){
                this.fetchingDevice = false;
            }
        }
    },
    mounted (){
        console.log(this.eventID);
        this.api_path = 'events/survey';
        this.completed_requests = 0;
        // Get Page Data And Reset Modal
        this.resetEditModal();
        this.fetchPageData()
            .then((res) =>{
                console.log('Promise Resolved');
                console.log(res);
                this.surveyData.raw = res.data.result.records[0];
                if(this.surveyData.raw.is_minor_mod){
                    this.survey_type = 'Field Update';
                }else{
                    this.survey_type = 'Survey';
                }
                
                if(this.surveyData.raw.connection_log_id != null){
                    this.fetchConnectionChangeLog();
                }
                
                if(this.surveyData.raw.device_log_id != null){
                    this.fetchDeviceChangeLog(); 
                }
                

                // Check For Sections
                if(this.surveyData.raw.survey_notes != null && this.surveyData.raw.survey_notes != ""){
                    this.showSection.survey_notes = true;
                }
                if(this.surveyData.raw.event_notes != null && this.surveyData.raw.event_notes != ""){
                    this.showSection.event_notes = true;

                    var event_notes_obj = JSON.parse(this.surveyData.raw.event_notes);
                    this.surveyData.raw.event_notes_pretty = JSON.stringify(event_notes_obj, null, 4);

                }
                if(this.surveyData.raw.condition != null && this.surveyData.raw.condition != ""){
                    this.showSection.condition = true;
                }
                if(this.surveyData.raw.condition_notes != null && this.surveyData.raw.condition_notes != ""){
                    this.showSection.condition_notes = true;
                }

                //this.editModal.actee = res.data.result.records[0];
                if(res.data.result.records[0].survey_photo != null){
                    if(res.data.result.records[0].survey_photo != ""){
                        this.required_requests++;
                        this.fetchGenericPhoto('survey_photo', res.data.result.records[0].survey_photo);
                    }
                }
                if(res.data.result.records[0].hazard_photo != null){
                    if(res.data.result.records[0].hazard_photo != ""){
                        this.required_requests++;
                        this.fetchGenericPhoto('hazard_photo', res.data.result.records[0].hazard_photo);
                    }
                }
                if(res.data.result.records[0].condition_photo != null){
                    if(res.data.result.records[0].condition_photo != ""){
                        this.required_requests++;
                        this.fetchGenericPhoto('condition_photo', res.data.result.records[0].condition_photo);
                    }
                }
                this.completed_requests++
            })
            .catch((error) =>{
                console.log('Promise Rejected');
                console.log(error);
                this.fetchingDevice = false;
            })
        //this.fetchSecondaryChoices();
    },
}
</script>

<style scoped>
    .not-changed-left{
        border-style:dotted none none solid;
        border-width: 1px 1px 1px 3px;
        border-color:darkgrey darkgrey darkgrey black;
    }
    .changed-left{
        border-style:dotted none none solid;
        border-width: 1px 1px 1px 3px;
        border-color:darkgrey darkgrey darkgrey goldenrod;
        background-color:lightgoldenrodyellow;
    }
    .not-changed{
        border-style:dotted none none none;
        border-width: 1px 1px 1px 1px;
        border-color:darkgrey darkgrey darkgrey darkgrey;
    }
    .changed{
        border-style:dotted none none none;
        border-width: 1px 1px 1px 1px;
        border-color:darkgrey darkgrey darkgrey darkgrey;
        background-color:lightgoldenrodyellow;
    }
</style>
